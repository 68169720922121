import React, { Component } from "react";
import axios from 'axios';

class DesktopNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionRequested: false,
      notifications: [],
    };
  }

  componentDidMount() {
    this.fetchNotifications();
    window.addEventListener('click', this.handlePageClick);
  }

  componentWillUnmount() {

    window.removeEventListener('click', this.handlePageClick);
    if (this.notificationTimeouts) {
      this.notificationTimeouts.forEach(timeout => clearTimeout(timeout));
    }
  }

  fetchNotifications = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/notifications');
        const notifications = response.data;
        this.setState({ notifications });
        this.scheduleNotifications(notifications);
    } catch (error) {
        console.error('Error fetching notifications:', error);
    }
}

  handlePageClick = () => {
    if (!this.state.permissionRequested) {
      this.requestPermission();
    }
  };

  requestPermission = () => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          console.log('Notification permission granted.');
        } else {
          console.log('Notification permission denied.');
        }
        this.setState({ permissionRequested: true });
      });
    }
  };

  scheduleNotifications = (notifications) => {
    this.notificationTimeouts = notifications.map(notification => {
      const delay = new Date(notification.showTime) - new Date();
      if (delay > 0) {
        return setTimeout(() => this.showNotification(notification), delay);
      }
      return null;
    }).filter(timeout => timeout !== null);
  }

  showNotification = (notification) => {
    if (Notification.permission === "granted" && 'serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.showNotification(notification.title, {
          body: notification.body,
          icon: notification.icon
        });
      });
    } else {
      console.log("Service Worker is not supported or permission not granted.");
    }
  };

  render() {
    return null;
  }
}

export default DesktopNotification;